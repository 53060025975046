<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="showAddEditModal">增加</a-button>
      </template>
      <div class="wrap">
        <p class="title">字典类型</p>
        <!-- 表格 -->
        <a-table
          :dataSource="dataSource"
          :columns="columns"
          :pagination="{ pageSize: pageSize, current: curPage, total: total }"
          :row-key="record => record.id"
          @change="handlePageChange"
        >
          <template #idx="row">
            {{ (curPage - 1) * pageSize + row.index + 1 }}
          </template>
          <template #action="row">
            <div class="action">
              <span class="del" @click="del(row)">删除</span>
            </div>
          </template>
        </a-table>
      </div>
      <add-edit-dictionary-type-modal
        ref="AddEditDictionaryTypeRef"
        @addOrUpdateSuccess="search"
      ></add-edit-dictionary-type-modal>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditDictionaryTypeModal from './AddEditDictionaryTypeModal';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '字典类型名称',
    dataIndex: 'type',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 220,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditDictionaryTypeModal
  },

  setup(props, ctx) {
    const visible = ref(false);
    const ModalBoxRef = ref();

    onMounted(() => {
      getDictionaryType();
    });

    const showModal = () => {
      visible.value = true;
      getDictionaryType();
    };
    // const visible = props.visible;

    // 字典类型弹窗 显示/隐藏
    const dictionaryTypeVisible = ref(false);

    // 用于审核的任务 id
    const taskId = ref();

    const AddEditDictionaryTypeRef = ref();
    const isEdit = ref(false);

    const playerState = reactive({
      player: {}
    });

    const state = reactive({
      dictionaryTypes: []
    });

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    const handleOk = async () => {};

    const handleCancel = () => {
      visible.value = false;
    };

    // 获取字典类型列表
    const getDictionaryType = async () => {
      try {
        const params = {
          curPage: tableState.curPage,
          pageSize: tableState.pageSize
        };
        const { data, success } = await api.getDictionaryType(params);
        if (success) {
          tableState.dataSource = data.records;
          tableState.total = data.total;
        }
      } catch (error) {
        console.error(error);
      }
    };

    // updateDictionaryDetail,
    // getDictionaryDetail,
    // getDictionaryDetailById,
    // delDictionaryDetailById,
    // addDictionaryType,
    // updateDictionaryType,
    // getDictionaryType,
    // getDictionaryTypeById,
    // delDictionaryTypeById

    // 获取字典详情列表
    const updateDictionaryDetail = async () => {
      try {
        const params = {
          curPage: tableState.curPage,
          pageSize: tableState.pageSize
        };
        const { data, success } = await api.updateDictionaryDetail(params);
        if (success) {
          tableState.dataSource = data.records;
          tableState.total = data.total;
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 获取字典详情列表
    // const getDictionaryDetail = async () => {
    //   try {
    //     const params = {
    //       curPage: tableState.curPage,
    //       pageSize: tableState.pageSize
    //     };
    //     const { data, success } = await api.getDictionaryDetail(params);
    //     if (success) {
    //       tableState.dataSource = data.records;
    //       tableState.total = data.total;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditDictionaryTypeRef.value.showModal(isEdit, rowData);
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `是否确认删除该条数据？`
        ),

        async onOk() {
          let { success, msg } = await api.delDictionaryTypeById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const approve = id => {
      dictionaryTypeVisible.value = true;
      taskId.value = id;
    };

    // 审批通过/不通过
    const approveFinish = async flag => {
      let params = {
        id: taskId.value,
        status: flag ? 1 : 3
      };
      let { success, msg } = await api.updateUavTask(params);
      if (success) {
        message.success('状态修改成功！');
        dictionaryTypeVisible.value = false;
        search();
      } else {
        message.error(msg);
      }
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getDictionaryType();
    };

    const search = () => {
      tableState.curPage = 1;
      getDictionaryType();
    };

    return {
      dictionaryTypeVisible,
      approveFinish,
      AddEditDictionaryTypeRef,
      showAddEditModal,
      isEdit,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,

      ...toRefs(state),
      handlePageChange,
      search,
      del,
      approve,
      playerState,
      showDetailModal,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: center;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          margin-right: 0;
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
