<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">增加字典类型</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="字典类型">
          <a-input v-model:value.trim="type" placeholder="请输入字段值" allowClear></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, onMounted, nextTick } from 'vue';
import api from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const state = reactive({
      dictionaryTypes: []
    });
    const formState = reactive({
      type: null // 字典类型
    });

    onMounted(() => {});

    const showModal = (flag, rowData) => {
      visible.value = true;
      getDictionaryType();
      nextTick(() => {
        isEdit.value = flag;
        if (flag.value) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
        }
      });
    };

    // 获取字典详情下拉选项
    const getDictionaryType = async () => {
      try {
        const params = {
          curPage: 1,
          pageSize: 100000
        };
        const { data, success } = await api.getDictionaryType(params);
        if (success) state.dictionaryTypes = data.records;
      } catch (error) {
        console.error(error);
      }
    };

    const handleOk = async () => {
      loading.value = true;
      Object.keys(formState).map(key => {
        if(!formState[key]) {
          loading.value = false
        }
      });
      if(!loading.value) {
        message.error('请输入完整')
        return false 
      } 
      let params = {
        ...formState
      };
      const { success } = await api.addDictionaryType(params);
      if (success) {
        message.success(`新增成功！`);
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = null;
      });
    };

    return {
      isEdit,
      labelCol,
      wrapperCol,
      ...toRefs(state),
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      getDictionaryType,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    color: blue;
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
</style>
