<template>
  <div class="wrap">
    <p class="title">字典管理</p>
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="字典类型">
        <a-select
          v-model:value="dictionaryType"
          placeholder="请选择字典类型"
          allowClear
          style="width: 200px"
          @change="handChange"
          @dropdownVisibleChange="dropdownVisibleChange"
          @select="handleSelect"
        >
          <a-select-option v-for="item in dictionaryTypes" :key="item.id" :value="item.id">
            {{ item.type }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="action">
        <a-button @click="showDictionaryTypeTableModal">字典类型列表</a-button>
        <a-button type="primary" @click="showAddEditModal">添加字段值</a-button>
      </a-form-item>
    </a-form>

    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #idx="row">
        {{ (curPage - 1) * pageSize + row.index + 1 }}
      </template>
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <!-- :visible="dictionaryTypeVisible" @cancel="dictionaryTypeVisible = false" -->
  <dictionary-type-table-modal ref="DictionaryTypeTableRef"></dictionary-type-table-modal>
  <!-- <a-modal :visible="dictionaryTypeVisible" @cancel="dictionaryTypeVisible = false">
  </a-modal> -->
  <add-edit-dictionary-modal ref="AddEditDictionaryRef" @addOrUpdateSuccess="search"></add-edit-dictionary-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditDictionaryModal from './AddEditDictionaryModal';
import DictionaryTypeTableModal from './DictionaryTypeTableModal';

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'idx'
    }
  },
  {
    title: '字典类型',
    dataIndex: 'type',
    align: 'center',
    ellipsis: true
  },
  {
    title: '字段值',
    dataIndex: 'detail',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center'
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    align: 'center'
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 220,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    AddEditDictionaryModal,
    DictionaryTypeTableModal
  },
  setup() {
    onMounted(() => {
      getDictionaryType();
      getDictionaryDetail();
    });

    // 字典类型弹窗 显示/隐藏
    const dictionaryTypeVisible = ref(false);

    // 用于审核的任务 id
    const taskId = ref();
    const DictionaryTypeTableRef = ref();

    const AddEditDictionaryRef = ref();
    const isEdit = ref(false);

    const router = useRouter();

    const videoUrl = ref('');
    const visible = ref(false);

    const playerState = reactive({
      player: {}
    });

    const state = reactive({
      dictionaryTypes: []
    });

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      dictionaryType: null
    });

    const handleSelect = () => {
      getDictionaryDetail();
    };

    const dropdownVisibleChange = open => {
      console.log(1)
      if (open) getDictionaryType();
    };

    // 获取字典详情列表
    const getDictionaryDetail = async () => {
      try {
        const params = {
          typeId: formState.dictionaryType,
          curPage: tableState.curPage,
          pageSize: tableState.pageSize
        };
        const { data, success } = await api.getDictionaryDetail(params);
        if (success) {
          tableState.dataSource = data.records;
          tableState.total = data.total;
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 获取字典详情列表
    const getDictionaryType = async () => {
      try {
        const params = {
          curPage: 1,
          pageSize: 100000
        };
        const { data, success } = await api.getDictionaryType(params);
        if (success) state.dictionaryTypes = data.records;
      } catch (error) {
        console.error(error);
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditDictionaryRef.value.showModal(isEdit.value, rowData);
    };

    const showDictionaryTypeTableModal = () => {
      DictionaryTypeTableRef.value.showModal();
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `是否确认删除该条数据？`
        ),

        async onOk() {
          let { success, msg } = await api.delDictionaryDetailById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const approve = id => {
      dictionaryTypeVisible.value = true;
      taskId.value = id;
    };

    // 审批通过/不通过
    const approveFinish = async flag => {
      let params = {
        id: taskId.value,
        status: flag ? 1 : 3
      };
      let { success, msg } = await api.updateUavTask(params);
      if (success) {
        message.success('状态修改成功！');
        dictionaryTypeVisible.value = false;
        search();
      } else {
        message.error(msg);
      }
    };

    // 跳转到无人机管理页面
    const jumpToUavManagePage = () => {
      router.push('/admin/machine-manage/uav-manage');
    };

    // 查看详情
    const showDetailModal = () => {};

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getDictionaryDetail();
    };

    const search = () => {
      tableState.curPage = 1;
      getDictionaryDetail();
      getDictionaryType();
    };

    const reset = () => {
      Object.keys(formState).map(key => (formState[key] = ''));
      search();
    };

    const handChange = () => {
      search();
    }
    

    
    return {
      dictionaryTypeVisible,
      DictionaryTypeTableRef,
      showDictionaryTypeTableModal,
      approveFinish,
      AddEditDictionaryRef,
      showAddEditModal,
      dropdownVisibleChange,
      handleSelect,
      isEdit,
      visible,
      videoUrl,
      ...toRefs(formState),
      ...toRefs(state),
      handlePageChange,
      search,
      reset,
      del,
      approve,
      playerState,
      showDetailModal,
      jumpToUavManagePage,
      ...toRefs(tableState),
      handChange
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: center;
    .ant-btn {
      margin-right: 20px;
    }
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          margin-right: 0;
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
